<template>
  <div :class="isFixed ? 'fixed-bar' : 'd-flex'">
    <label class='ml-2' v-if="!isFixed">{{`${$t('search-result.by-sort')} : `}}</label>
    <ul class="list-inline propFilter-top">
      <!-- <li class="list-inline-item">
        <input type="checkbox" class="custom-control-input" id="fltrEverything-d" v-model="all">
        <label for="fltrEverything-d">
          <span>Everything</span>
        </label>
      </li> -->
      <li :class="['list-inline-item', priceLTH ? 'active':'']" v-if="buttonList.indexOf('priceLTH') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="priceLTH"
          @click="setKindSort('priceLTH')"
        />
        <!-- <b-avatar v-if="isFixed" :variant="priceLTH ? 'primary': 'secondary'" button @click="setKindSort('priceLTH')" >
          <b-icon-sort-numeric-down/>
        </b-avatar> -->
        <label for="priceLTH">
          <span>{{ $t('search-result.priceLTH') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item', priceHTL ? 'active':'']" v-if="buttonList.indexOf('priceHTL') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="priceHTL"
          @click="setKindSort('priceHTL')"
        />
        <!-- <b-avatar v-if="isFixed" :variant="priceHTL ? 'primary': 'secondary'" button @click="setKindSort('priceHTL')">
          <b-icon-sort-numeric-down-alt/>
        </b-avatar> -->
        <label for="priceHTL">
          <span>{{ $t('search-result.priceHTL') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item', gradeLTH ? 'active':'']" v-if="buttonList.indexOf('gradeLTH') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="gradeLTH"
          @click="setKindSort('gradeLTH')"
        />
        <!-- <b-avatar v-if="isFixed" :variant="gradeLTH ? 'primary': 'secondary'" button @click="setKindSort('gradeLTH')">
          <b-icon-sort-down/>
        </b-avatar> -->
        <label for="gradeLTH">
          <span>{{ $t('search-result.gradeLTH') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item', gradeHTL ? 'active':'']" v-if="buttonList.indexOf('gradeHTL') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="gradeHTL"
          @click="setKindSort('gradeHTL')"
        />
        <!-- <b-avatar v-if="isFixed" :variant="gradeHTL ? 'primary': 'secondary'" button @click="setKindSort('gradeHTL')">
          <b-icon-sort-down-alt/>
        </b-avatar> -->
        <label for="gradeHTL">
          <span>{{ $t('search-result.gradeHTL') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item', daysLTH ? 'active':'']" v-if="buttonList.indexOf('daysLTH') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="daysLTH"
          @click="setKindSort('daysLTH')"
        />
        <!-- <b-avatar v-if="isFixed" :variant="daysLTH ? 'primary': 'secondary'" button @click="setKindSort('daysLTH')">
          <span>{{ $t('search-result.daysLTH') }}</span>
        </b-avatar> -->
        <label for="daysLTH">
          <span>{{ $t('search-result.daysLTH') }}</span>
        </label>
      </li>
      <li :class="['list-inline-item', daysHTL ? 'active':'']" v-if="buttonList.indexOf('daysHTL') > -1">
        <button
          type="checkbox"
          class="custom-control-input"
          id="daysHTL"
          @click="setKindSort('daysHTL')"
        />
        <!-- <b-avatar v-if="isFixed" :variant="daysHTL ? 'primary': 'secondary'" button @click="setKindSort('daysHTL')">
          <span>{{ $t('search-result.daysHTL') }}</span>
        </b-avatar> -->
        <label for="daysHTL">
          <span>{{ $t('search-result.daysHTL') }}</span>
        </label>
      </li>
      <!-- <li class="list-inline-item" v-if="buttonList.indexOf('grade') > -1">
        <input
          type="checkbox"
          class="custom-control-input"
          id="fltrGrade-d"
          v-model="grade"
        />
        <label for="fltrGrade-d">
          <span>{{ $t('search-result.grade') }}</span>
        </label>
      </li> -->
      <li class="list-inline-item" v-if="buttonList.indexOf('duration') > -1">
        <input
          type="checkbox"
          class="custom-control-input"
          id="fltrTripDuration-d"
          v-model="duration"
        />
        <label for="fltrTripDuration-d">
          <span>{{ $t('search-result.duration-trip') }}</span>
        </label>
      </li>

      <li class="list-inline-item" v-if="buttonList.indexOf('review') > -1">
        <input
          type="checkbox"
          class="custom-control-input"
          id="fltrReview-d"
          v-model="review"
        />
        <label for="fltrReview-d">
          <span>{{ $t('search-result.review') }}</span>
        </label>
      </li>
      <li class="list-inline-item" v-if="buttonList.indexOf('stars') > -1">
        <select class="form-control selectStar" id="fltrStars-d" v-model="stars">
          <option selected disabled>{{ $t('search-result.starts') }}</option>
          <option value="">1 {{ $t('search-result.start') }}</option>
          <option value="">2 {{ $t('search-result.start') }}</option>
          <option value="">3 {{ $t('search-result.start') }}</option>
          <option value="">4 {{ $t('search-result.start') }}</option>
        </select>
      </li>
      <li class="list-inline-item" v-if="buttonList.indexOf('home-apartment') > -1">
        <input
          type="checkbox"
          class="custom-control-input"
          id="fltrHomeApprt-d"
          v-model="homeApartment"
        />
        <label for="fltrHomeApprt-d">
          <span>{{ $t('search-result.entire-home-apartment') }}</span>
        </label>
      </li>
      <li class="list-inline-item" v-if="buttonList.indexOf('top-pick') > -1">
        <input type="checkbox" class="custom-control-input" id="fltrTopPic-d" v-model="topPick"/>
        <label for="fltrTopPic-d">
          <span>{{ $t('search-result.our-top-picks') }}</span>
        </label>
      </li>
      <li class="list-inline-item" v-if="buttonList.indexOf('name-filter') > -1">
        <label for="fltrHotelName">
          <TextSearchBox @updateSearch="updateSearch"/>
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
// import { BAvatar, BIconSortNumericDownAlt, BIconSortNumericDown, BIconSortDown, BIconSortDownAlt } from 'bootstrap-vue';

export default {
  name: 'FilterButtons',
  components: {
    // BAvatar,
    // BIconSortNumericDownAlt,
    // BIconSortNumericDown,
    // BIconSortDown,
    // BIconSortDownAlt,
    TextSearchBox: () => import('@/components/searchResult/atoms/TextSearchBox'),
  },
  props: {
    buttonList: {
      type: Array,
      defult: () => [],
    },
    isFixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      all: true,
      priceHTL: false,
      priceLTH: false,
      gradeLTH: false,
      gradeHTL: false,
      daysLTH: false,
      daysHTL: false,
      duration: false,
      review: false,
      stars: false,
      homeApartment: false,
      topPick: false,
      hotelName: '',
    };
  },
  watch: {
    // priceLTH() {
    //   this.$emit('updateSort', ['priceLTH', this.priceLTH]);
    // },
    // priceHTL() {
    //   this.$emit('updateSort', ['priceHTL', this.priceHTL]);
    // },
    // grade() {
    //   this.$emit('updateSort', ['grade', this.grade]);
    // },
  },
  created() {
    // this.priceLTH = true;
    // this.$emit('updateSort', ['price', true]);
  },
  methods: {
    updateSearch(str) {
      this.$emit('updateSort', ['hotelName', str]);
    },
    setKindSort(pKind) {
      this.all = false;
      this.priceHTL = false;
      this.priceLTH = false;
      this.gradeLTH = false;
      this.gradeHTL = false;
      this.daysLTH = false;
      this.daysHTL = false;
      this.grade = false;
      this.duration = false;
      this.review = false;
      this.stars = false;
      this.homeApartment = false;
      this.topPick = false;
      this[pKind] = true;
      let kind = '', value = false;
      if (pKind.indexOf('price') > -1) {
        kind = 'price';
        value = pKind === 'priceLTH';
      } else if (pKind.indexOf('grade') > -1) {
        kind = 'grade';
        value = pKind === 'gradeLTH';
      } else if (pKind.indexOf('days') > -1) {
        kind = 'days';
        value = pKind === 'daysLTH';
      }
      this.$emit('updateSort', [kind, value]);
    },
  },
};
</script>
<style scoped>
.fixed-bar {
  max-width: min-content;
  background: #fff;
  position: fixed;
  box-shadow: gray 1px 1px 20px;
  top: 50vh;
  transition: 0.2s all;
  margin-top: -95px;
  left: -50px;
  border-radius: 0.25rem;
  padding: 1.25rem 0.75rem;
}
.fixed-bar::after {
  content: '❮';
  color: #000;
  background: #fff;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  position: absolute;
  top: calc(50% - 20px);
  right: -8px;
  font-size: 1.7rem;
}
.fixed-bar:hover {
  left: 5px;
}
.fixed-bar .custom-control-input {
  width: 40px;
}
.fixed-bar .propFilter-top {
  margin: 0;
}
.fixed-bar .propFilter-top .list-inline-item {
  margin-left: 0;
  margin-right: 0;
}
.fixed-bar .propFilter-top .list-inline-item:not(:first-child){
  margin-top: 10px;
}
.fixed-bar .propFilter-top .list-inline-item span{
  cursor: pointer;
}
.fixed-bar .b-avatar-custom span{
  font-size: 0.55rem;
}
.list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: 0;
}
.list-inline-item.active > label span {
  background-color: rgb(0, 97, 171);
  color: #fff;
}
@media (min-width: 1500px) {
  .fixed-bar {
    left: 5px;
  }
  .fixed-bar::after {
    display: none;
  }
}
</style>
